import React, { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { ThemeContext } from './contexts/ThemeContext';
import ScrollToTop from './utils/ScrollToTop';
import './App.css';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner'; // اضافه کردن لودینگ



// بارگذاری تنبل صفحات
const Main = React.lazy(() => import('./pages/Main/Main'));
const BlogPage = React.lazy(() => import('./pages/Blog/BlogPage'));
const ProjectPage = React.lazy(() => import('./pages/Projects/ProjectPage'));
const ProjectDetails = React.lazy(() => import('./pages/ProjectDetails/ProjectDetails'));
const LandingPage = React.lazy(() => import('./pages/Landing/LandingPage'));
const SkillsPage = React.lazy(() => import('./pages/Skills/SkillsPage'));
const MarketingPage = React.lazy(() => import('./pages/Marketing/MarketingPage'));
const ServicePage = React.lazy(() => import('./pages/Service/ServicePage'));
const HoldingPage = React.lazy(() => import('./pages/Holding/HoldingPage'));
const MavadPage = React.lazy(() => import('./pages/Mavad/MavadPage'));
const ItemDetails = React.lazy(() => import('./pages/ItemDetails/ItemDetails'));
const LandingDetails = React.lazy(() => import('./pages/LandingDetails/LandingDetails'));
const CosmeticsPage = React.lazy(() => import('./pages/Cosmetics/CosmeticsPage'));
const BeautyPage = React.lazy(() => import('./pages/Beauty/BeautyPage'));
const BackToTop = React.lazy(() => import('./components/BackToTop/BackToTop'));
const ServiceDetails = React.lazy(() => import('./pages/ServiceDetails/ServiceDetails'));
const TrainPage = React.lazy(() => import('./pages/Train/TrainPage'));
const TrainingDetails = React.lazy(() => import('./pages/TrainingDetails/TrainingDetails'));
const DoctorDetails = React.lazy(() => import('./pages/DoctorDetails/DoctorDetails'));
const BrandDetails = React.lazy(() => import('./pages/BrandDetails/BrandDetails'));
const CenterDetails = React.lazy(() => import('./pages/CenterDetails/CenterDetails'));
const WeightLossDetails = React.lazy(() => import('./pages/WeightLossDetails/WeightLossDetails'));
const HairSkinDetails = React.lazy(() => import('./pages/HairSkinDetails/HairSkinDetails'));


function App() {
  const { theme } = useContext(ThemeContext);

  console.log("%cDEVELOPER PORTFOLIO", `color:${theme.primary}; font-size:50px`);
  console.log("%chttps://github.com/hhhrrrttt222111/developer-portfolio", `color:${theme.tertiary}; font-size:20px`);
  // console.log = console.warn = console.error = () => {};

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/landing/" element={<LandingPage />} />
            <Route path="/Skills" element={<SkillsPage />} />
            <Route path="/Marketing" element={<MarketingPage />} />
            <Route path="/Service" element={<ServicePage />} />
            <Route path="/Holding" element={<HoldingPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/mavad/:type" element={<MavadPage />} />
            <Route path="/item/:id" element={<ItemDetails />} />
            <Route path="/product/:id" element={<LandingDetails />} />
            <Route path="/cosmetics" element={<CosmeticsPage />} />
            <Route path="/beauty" element={<BeautyPage />} />
            <Route path="/service/:id" element={<ServiceDetails />} />
            <Route path="/train" element={<TrainPage />} />
            <Route path="/trainings/:id" element={<TrainingDetails />} />
            <Route path="/doctor-details/:id" element={<DoctorDetails />} />
           <Route path="/brand-details/:id" element={<BrandDetails />} />
           <Route path="/center-details/:id" element={<CenterDetails />} />
            <Route path="/weight-loss-details/:id" element={<WeightLossDetails />} />
            <Route path="/hair-skin-details/:id" element={<HairSkinDetails />} />
            {/* هدایت به صفحه اصلی */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
        <Suspense fallback={null}>
          <BackToTop />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
